<template>
  <section class="content-wrapper">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'ContentWrapper',
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  background-color: white;
  border: 1px solid black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 5px;
  border-top: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
