<template>
  <content-wrapper>
    <h2>404 page not found</h2>
  </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper';

export default {
  name: 'NotFound',
  components: {
    ContentWrapper,
  },
};
</script>
